<template>
	<router-link class="company-link" :to="'/companies/' + company.id" v-if="company">
		<lazy-background :src="company.image_source.thumb100" class="round"></lazy-background>
		<span class="name">{{ company.name }}</span>
	</router-link>
</template>

<script>
	export default {
		props: ['company']
	}
</script>