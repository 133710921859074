<template>
	<transition name="fade" mode="out-in">
		<editable-grid-card :add-handler="createProject" class="projects" :permission="(id)?'companies.edit':'companies.create'" v-if="id" @cancel="errors = {}">
			<template #title>
				<icon icon="commerce/wallet/money-order"></icon> 
				Projects
			</template>
			<template #view="{edit}">
				<responsive-table v-if="company.projects && company.projects.length > 0" :priority="[]">
					<responsive-tr v-for="(project, index) in company.projects" :key="project.id" @click.prevent="$navigate('/companies/' + id + '/projects/' + project.id)">
						<template v-slot="{hidden}">
							<responsive-td :pos="0" class="image-col" :hidden="hidden"><lazy-background :src="project.image_source.thumb100"></lazy-background></responsive-td>
							<responsive-td :pos="1" :hidden="hidden">{{ project.name }}</responsive-td>
						</template>
						<template #actions>
							<icon-action @click="deleting = project">essentials/basics/close-circle</icon-action>
						</template>
					</responsive-tr>
				</responsive-table>
				<p v-else>
					This company has no projects yet.
				</p>
			</template>
			<delete-modal v-model="deleting" display="name" :remove="remove"></delete-modal>
		</editable-grid-card>
	</transition>
</template>

<script>
	export default {
		props: ['value', 'form', 'id'],
		data() {
			return {
				company: this.value,
				deleting: null,
				errors: {}
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			endpoint() {
				return '/json/companies/' + this.id + '/projects';
			}
		},
		watch: {
			value() {
				this.company = this.value;
			},
			company() {
				this.$emit('input', this.company);
			}
		},
		methods: {
			async createProject() {
				await this.$navigate('/companies/' + this.id + '/projects/create');
			},
			async remove() {
				const data = await this.$postDelete(this.endpoint + '/' + this.deleting.id);
				this.company.projects = data.projects;
			}
		}
	}
</script>