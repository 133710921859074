export default {
	async _refreshCsrf() {
		try { 
			let csrf = await this.$get('/json/csrf');
			document.head.querySelector('meta[name="csrf-token"]').content = csrf.token;
			this.$http.defaults.headers.common['X-CSRF-TOKEN'] = csrf.token;
		} catch(e) {
			console.log(e);
		}
	},
	async upload(action, data) {
		let obj = {};
		obj = await this._cycleFiles(data, obj);
		return await this.$request(action, obj);
	},
	async _cycleFiles(data, obj) {
		let self = this;
		if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
			obj = {};
			let awaits = [];
            Object.keys(data).forEach((key) => {
            	let p = self._cycleFiles(data[key], obj[key]);
            	awaits.push(p);
                obj[key] = p;
            });
            const keys = Object.keys(data);
            const results = await Promise.all(awaits);
            for(let i = 0; i < results.length; i++) {
            	obj[keys[i]] = results[i];
            }
            return obj;
        }
        if(data instanceof File) {
        	obj = await this._sendFile(data);
        	return obj;
        }
        obj = JSON.parse(JSON.stringify(data));
        return obj;
	},
	_sendFile(file) {
		const id = uuidv4();
		return new Promise((resolve, reject) => {
			this.sockets.subscribe('uploads.finish.' + id, (data) => {
				this.sockets.unsubscribe('uploads.finish.' + id);
				resolve({file: id, type: 'upload', name: file.name, mime: file.type});
			});
			this._sendChunk(id, file, 0);
		});
	},
	_sendChunk(id, file, index) {
		const chunkSize = 1024 * 512;
		const blob = file.slice(index * chunkSize, (index * chunkSize) + chunkSize);
		if(blob.size === 0) {
			this.$socket.emit('uploads.finish.' + id);
			return;
		}
		this.$socket.emit('uploads.chunk.' + id + '.' + index, blob);
		this.sockets.subscribe('uploads.chunk.' + id + '.' + index, (data) => {
			this.sockets.unsubscribe('uploads.chunk.' + id + '.' + index);
			this._sendChunk(id, file, index + 1);
		});
	},
	request(action, data) {
		return new Promise((resolve, reject) => {
            this.sockets.subscribe(action, (d) => {
                this.sockets.unsubscribe(action);
                this.sockets.unsubscribe(action + '.errors');
                if(d && d.message) {
					this.$setMessages([d.message]);
				}
                resolve(d);
            });
            this.sockets.subscribe(action + '.errors', async (d) => {
            	this.sockets.unsubscribe(action);
                this.sockets.unsubscribe(action + '.errors');
                let error = new Error();
				error.code = d.code;
				if(d.message && [422, 500].indexOf(d.code) === -1) {
					this.$setErrors([d.message]);
				}
				if(d.code === 401 && this.$route.path !== '/reset-password') {
					this.$store.state.user = new User(null);
					await this.$navigate('/');
				}
				if(d.code === 403) {
					await this.$navigate(d.redirect || '/');
				}
				if(d.code === 422 && (d.errors || d.message)) {
					error.data = d.errors;
					error.message = d.message;
				}
				if(d.code === 500) {
					this.$setErrors(['An unknown error occurred']);
				}
            	reject(error);
            });
            this.$socket.emit(action, data);
        });
	},
	async decrypt(endpoint) {
		const data = await this.$get(endpoint);
		return data.value;
	},
	async get(endpoint, data, headers) {
		return this._request('get', endpoint, data, headers);
	},
	async post(endpoint, data, headers) {
		return this._request('post', endpoint, data, headers);
	},
	async put(endpoint, data, headers) {
		return this._request('put', endpoint, data, headers);
	},
	async patch(endpoint, data, headers) {
		return this._request('patch', endpoint, data, headers);
	},
	async postDelete(endpoint, data, headers) {
		return this._request('delete', endpoint, data, headers);
	},
	async _request(method, endpoint, params, headers) {
		let formattedHeaders = (headers)?headers:{};
		if(this.$socket && this.$socket.id) {
			formattedHeaders['X-Socket-ID'] = this.$socket.id;
		}
		let formattedParams = (method === 'get')?{params: params, headers: formattedHeaders}:params;
		try {
			let response = await this.$http[method](endpoint, formattedParams, {headers: formattedHeaders});
			if(response.data.message) {
				this.$setMessages([response.data.message]);
			}
			return response.data;
		} catch(e) {
			if(!e.response) {
				throw e;
				return;
			}
			let error = new Error();
			error.code = e.response.status;
			if(e.response.status === 419){
				await this._refreshCsrf();
				return await this._request(method, endpoint, params, headers);
			}
			if(e.response.data && e.response.data.message && [422, 500].indexOf(e.response.status) === -1) {
				this.$setErrors([e.response.data.message]);
			}
			if(e.response.status === 401 && this.$route.path !== '/reset-password') {
				this.$store.state.user = new User(null);
				await this.$navigate('/');
			}
			if(e.response.status === 403) {
				await this.$navigate(e.response.data.redirect || '/');
			}
			if(e.response.status === 422 && (e.response.data.errors || e.response.data.message)) {
				error.data = e.response.data.errors;
				error.message = e.response.data.message;
			}
			if(e.response.status === 500) {
				this.$setErrors(['An unknown error occurred']);
			}
			throw error;
			
		}
	},
	_toFormData(obj) {
		const fd = new FormData();
        this._buildFormData(fd, obj);
        return fd;
	},
	_buildFormData(fd, data, parent) {
		let self = this;
		if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach((key) => {
                self._buildFormData(fd, data[key], (parent)?`${parent}[${key}]`:key);
            });
        } else {
            const value = (data == null)?'':data;
            fd.append(parent, value);
        }
	}
}