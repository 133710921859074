<template>
	<editable-card class="details" :permission="(id)?'companies.edit':'companies.create'" :save="save" :edit-only="!id" @cancel="errors = {}">
		<template #title>
			<icon icon="commerce/business/briefcase"></icon> 
			Details
		</template>
		<template #view>
			<lazy-background :src="(company.image && company.image.full)?company.image.full:''" class="card-image company-image"></lazy-background>
			<div class="info">
				<h4>{{ company.name }}</h4>
				<div class="address" v-if="company.country">
					{{ company.address }}<br/>
					{{ company.city }}, {{ company.state }} {{ company.zip }}
				</div>
			</div>
		</template>
		<template #edit>
			<input-lazy-background v-model="ecompany.image.full" class="company-image"></input-lazy-background>
			<div class="info">
				<input-text type="text" v-model="ecompany.name" label="Company Name" :errors="errors.name"></input-text>
				<input-address v-model="ecompany" label="Primary Address" :api-key="form.google_key"></input-address>
			</div>
		</template>
	</editable-card>
</template>

<script>
	export default {
		props: ['value', 'form', 'id'],
		data() {
			return {
				company: this.value,
				ecompany: this.$clone(this.value),
				errors: {}
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			endpoint() {
				return '/json/companies/' + ((this.id)?this.id:'create');
			}
		},
		watch: {
			value() {
				this.company = this.value;
				this.ecompany = this.$clone(this.value);
			},
			company() {
				this.$emit('input', this.company);
			}
		},
		methods: {
			async save() {
				this.errors = {};
				try {
					const data = await this.$upload('companies.' + ((this.id)?'update':'create'), this.ecompany);
					this.company = data.model;
					this.ecompany = data.model;
					this.$store.state.pageTitle = this.company.name;
					if(!this.id) {
						await this.$navigate('/companies/' + this.company.id);
					}
				} catch(e) {
					this.errors = e.data ?? {};
					throw e;
				}
			}
		}
	}
</script>