<template>
	<div class="file-holder">
		<context-menu>
			<div class="file" :class="{active: active}" @click.prevent="updateValue" draggable="true" @dragstart="dragged = {type: 'media', item: file}">
				<div class="preview">
					<icon :icon="file.icon" v-if="!file.thumbs.thumb320"></icon>
					<lazy-image :src="file.thumbs.thumb320" v-if="file.thumbs.thumb320"></lazy-image>
				</div>
				<div class="name">{{ file.name }}</div>
			</div>
			<template #menu>
				<a href="#" @click.prevent="download"><icon icon="technology/cloud/cloud-download"></icon> Download</a>
				<a href="#" @click.prevent="editing = JSON.parse(JSON.stringify(file))"><icon icon="essentials/settings/edit-circle"></icon> Edit</a>
				<a href="#" @click.prevent="deleting = true"><icon icon="office/documents/trash"></icon> Delete</a>
			</template>
		</context-menu>
		<modal v-model="deleting" :disabled="loading" class="delete-modal">
			<template #header><h3>Delete {{ file.name }}</h3></template>
			<loader :loading="loading"></loader>
			<p>Are you sure you want to delete {{ file.name }}? Doing so will remove this file referenced anywhere on the site.</p>
			<template #footer>
				<icon-button icon="essentials/alerts/cancel" class="warning" @click.prevent="deleting = false" :disabled="loading">Cancel</icon-button>
				<icon-button icon="essentials/basics/check-circle" class="danger" @click.prevent="remove" :disabled="loading">Delete</icon-button>
			</template>
		</modal>
		<media-file-modal v-model="file" :open="editing" @input:open="editing = $event"></media-file-modal>
	</div>
</template>

<script>
	export default {
		props: ['value', 'parent', 'selected', 'dragging'],
		data() {
			return {
				file: this.value,
				folder: this.parent,
				chosen: this.selected,
				dragged: this.dragging,
				deleting: false,
				loading: false,
				editing: false,
				errors: {}
			};
		},
		watch: {
			value() {
				this.file = this.value;
			},
			file() {
				this.$emit('input', this.file);
			},
			folder() {
				this.$emit('input:parent', this.folder);
			},
			parent() {
				this.folder = this.parent;
			},
			selected() {
				this.chosen = this.selected;
			},
			chosen() {
				this.$emit('input:selected', this.chosen);
			},
			dragging() {
				this.dragged = this.dragging;
			},
			dragged() {
				this.$emit('input:dragging', this.dragged);
			}
		},
		computed: {
			active() {
				if(!this.chosen) {
					return false;
				}
				return this.chosen.findIndex(x => x.id === this.file.id) > -1;
			}
		},
		methods: {
			download() {
				let a = document.createElement('a');
				a.download = this.file.name;
				a.href = this.file.link;
				a.dispatchEvent(new MouseEvent('click'));
			},
			updateValue() {
				if(!this.chosen) {
					this.chosen = [];
				}
				const index = this.chosen.findIndex(x => x.id === this.file.id);
				if(index !== -1) {
					this.chosen.splice(index, 1);
					return;
				}
				this.chosen.push(this.file);
			},
			async remove() {
				this.loading = true;
				try {
					await this.$postDelete('/admin/json/media/file/' + this.file.id);
					if(this.chosen && this.chosen.findIndex(x => x.id === this.file.id) > -1) {
						this.chosen.splice(this.chosen.findIndex(x => x.id === this.file.id), 1);
					}
					this.folder.media.splice(this.folder.media.findIndex(x => x.id === this.file.id), 1);
				} catch(e) {
					this.errors = e.data || {};
				}
				this.loading = false;
			},
			async save() {
				this.file = this.editing;
				this.editing = null;
			}
		}
	}
</script>