export default {
    routes: [{
        path: '/companies',
        component: () => import('../pages/companies/Index.vue'),
        name: 'companies.index',
        meta: {
            name: 'Companies',
            title: window.titlePrefix,
            permissions: ['companies.view']
        }
    }, {
        path: '/companies/create',
        component: () => import('../pages/companies/View.vue'),
        name: 'companies.create',
        meta: {
            name: 'Companies',
            title: window.titlePrefix,
            permissions: ['companies.create']
        }
    }, {
        path: '/companies/:id',
        component: () => import('../pages/companies/View.vue'),
        props: true,
        name: 'companies.view',
        meta: {
            name: 'Companies',
            title: window.titlePrefix,
            permissions: ['companies.view']
        }
    }, {
        path: '/companies/:id/projects/create',
        component: () => import('../pages/companies/projects/View.vue'),
        props: true,
        name: 'companies.projects.create',
        meta: {
            name: 'Project',
            title: window.titlePrefix,
            permissions: ['companies.view']
        }
    }, {
        path: '/companies/:id/projects/:projectId',
        component: () => import('../pages/companies/projects/View.vue'),
        props: true,
        name: 'companies.projects.view',
        meta: {
            name: 'Project',
            title: window.titlePrefix,
            permissions: ['companies.view']
        }
    }]
}