<template>
	<a href="#" @click.prevent="logout">
		<icon icon="arrows/arrow/arrow-curved-alt-left-circle"></icon>
		<span>Logout</span>
	</a>
</template>

<script>
	export default {
		computed: {
			user: {
				get() {
					return this.$store.state.user;
				},
				set(value) {
					this.$store.state.user = new User(value);
				}
			},
			loading: {
				get() {
					return this.$store.state.loading;
				},
				set(value) {
					this.$store.state.loading = value;
				}
			}
		},
		methods: {
			async logout(){
				this.loading++;
				try {
					let user = await this.$request('logout');
					this.user = user.user;
					await this.$navigate('/');
				} catch(e) {
					console.log(e);
				}
				this.loading--;
			}
		}
	}
</script>
