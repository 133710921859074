<template>
	<modal v-model="visible" :disabled="loading" @keydown.enter.prevent.stop="" @keyup.enter.prevent.stop="save">
		<template #header><h3>{{ (editing.id)?'Update':'Create' }} Folder</h3></template>
		<loader :loading="loading"></loader>
		<div v-if="unsaved">
			<input-text label="Name" v-model="unsaved.name" :errors="errors.name"></input-text>
		</div>
		<template #footer>
			<button @click.prevent="visible = false" :disabled="loading">Close</button>
			<button @click.prevent="save" :disabled="loading">{{ (editing.id)?'Save':'Create' }}</button>
		</template>
	</modal>
</template>

<script>
	export default {
		props: ['value', 'folder', 'open'],
		data() {
			return {
				visible: false,
				loading: false,
				parent: this.value,
				editing: this.folder,
				unsaved: JSON.parse(JSON.stringify(this.folder)),
				errors: {}
			};
		},
		watch: {
			parent() {
				this.$emit('input', this.parent);
			},
			value() {
				this.parent = this.value;
			},
			editing() {
				this.$emit('input:folder', this.editing);
			},
			folder() {
				this.editing = this.folder;
				this.unsaved = JSON.parse(JSON.stringify(this.folder));
			},
			open() {
				this.visible = this.open;
				this.unsaved = JSON.parse(JSON.stringify(this.folder));
			},
			visible() {
				this.$emit('input:open', this.visible);
			}
		},
		methods: {
			async save() {
				this.loading = true;
				this.errors = {};
				try {
					const data = await this['$' + ((this.unsaved.id)?'patch':'post')]('/admin/json/media/folder', this.unsaved);
					if(!this.unsaved.id) {
						this.parent.folders.push(data.folder);
					}
					this.editing = data.folder;
					this.visible = false;
				} catch(e) {
					this.errors = e.data || {};
				}
				this.loading = false;
			}
		}
	}
</script>