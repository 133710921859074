<template>
	<a href="#" @click.prevent="open" @drop="drop" @dragover="dragover">
		<slot></slot>
	</a>
</template>

<script>
	export default {
		props: ['value', 'to', 'dragging'],
		data() {
			return {
				loading: false,
				folder: this.value,
				dragged: this.dragging
			};
		},
		watch: {
			folder() {
				this.$emit('input', this.folder);
			},
			value() {
				this.folder = this.value;
			},
			dragged() {
				this.$emit('input:dragging', this.dragged);
			},
			dragging() {
				this.dragged = this.dragging;
			}
		},
		methods: {
			dragover(event) {
				if((this.to?.id || null)  === this.folder.id) {
					return;
				}
				event.preventDefault();
			},
			async open() {
				this.loading = true;
				try {
					this.folder = await this.$get('/admin/json/media/folder/' + (this.to?.id || ''));
				} catch(e) {
					console.log(e);
				}
				this.loading = false;
			},
			async drop(event) {
				this.loading = true;
				try {
					const endpoint = (this.dragged.type === 'media')?'file':'folder';
					await this.$post('/admin/json/media/' + endpoint + '/move', {parent: (this.to?.id || null), child: this.dragged.item.id});
					this.folder[this.dragged.type].splice(this.folder[this.dragged.type].findIndex(x => x.id === this.dragged.item.id), 1);
					this.dragged = null;
				} catch(e) {
					console.log(e);
				}
				this.loading = false;
			}
		}
	}
</script>