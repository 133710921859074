<template>
	<div class="folder-holder" :class="{over: over}">
		<context-menu>
			<div class="folder" @dblclick="open" draggable="true" @dragstart="dragged = {type: 'folders', item: folder}" @dragover="dragover" @dragleave="dragleave" @drop="drop">
				<icon icon="office/documents/folder"></icon>
				{{ folder.name }}
			</div>
			<template #menu>
				<a href="#" @click.prevent="editing = folder"><icon icon="essentials/settings/edit-circle"></icon> Edit</a>
				<a href="#" @click.prevent="deleting = true"><icon icon="office/documents/trash"></icon> Delete</a>
			</template>
		</context-menu>
		<modal v-model="deleting" :disabled="loading" class="delete-modal">
			<template #header><h3>Delete {{ folder.name }}</h3></template>
			<loader :loading="loading"></loader>
			<p>Are you sure you want to delete {{ folder.name }}? Doing so will remove this folder and any files/folders underneath.</p>
			<template #footer>
				<icon-button icon="essentials/alerts/cancel" class="warning" @click.prevent="deleting = false" :disabled="loading">Cancel</icon-button>
				<icon-button icon="essentials/basics/check-circle" class="danger" @click.prevent="remove" :disabled="loading">Delete</icon-button>
			</template>
		</modal>
		<media-folder-modal 
			v-model="heir" 
			:folder="folder" 
			@input:folder="folder = $event"
			:open="editing"
			@input:open="editing = $event"
		></media-folder-modal>
	</div>
</template>

<script>
	export default {
		props: ['value', 'parent', 'dragging'],
		data() {
			return {
				folder: this.value,
				heir: this.parent,
				dragged: this.dragging,
				loading: false,
				deleting: false,
				errors: {},
				over: false,
				editing: false
			};
		},
		watch: {
			folder() {
				this.$emit('input', this.folder);
			},
			value() {
				this.folder = this.value;
			},
			parent() {
				this.heir = this.parent;
			},
			heir() {
				this.$emit('input:parent', this.heir);
			},
			dragging() {
				this.dragged = this.dragging;
			},
			dragged() {
				this.$emit('input:dragging', this.dragged);
			}
		},
		methods: {
			dragover(event) {
				if(this.dragged.type === 'folders' && this.dragged.item.id === this.folder.id) {
					return;
				}
				event.preventDefault();
				this.over = true;
			},
			dragleave(event) {
				this.over = false
			},
			async open() {
				this.loading = true;
				try {
					this.heir = await this.$get('/admin/json/media/folder/' + this.folder.id);
				} catch(e) {}
				this.loading = false;
			},
			async drop(event) {
				this.over = false;
				this.loading = true;
				try {
					const endpoint = (this.dragged.type === 'media')?'file':'folder';
					await this.$post('/admin/json/media/' + endpoint + '/move', {parent: this.folder.id, child: this.dragged.item.id});
					this.heir[this.dragged.type].splice(this.heir[this.dragged.type].findIndex(x => x.id === this.dragged.item.id), 1);
					this.dragged = null;
				} catch(e) {
					console.log(e);
				}
				this.loading = false;
			},
			async remove() {
				this.loading = true;
				try {
					await this.$postDelete('/admin/json/media/folder/' + this.folder.id);
					this.heir.folders.splice(this.heir.folders.findIndex(x => x.id === this.folder.id), 1);
				} catch(e) {
					this.errors = e.data || {};
				}
				this.loading = false;
			}
		}
	}
</script>