export default {
    routes: [{
        path: '/settings',
        component: () => import('../pages/settings/Index.vue'),
        meta: {
            name: 'Settings',
            title: window.titlePrefix,
            permissions: ['settings.view']
        }, 
        children: [{
            path: '',
            component: () => import('../pages/settings/tabs/General.vue'),
            name: 'settings.general',
            meta: {
                name: 'Settings',
                title: 'General',
                permissions: ['settings.view']
            }
        }]
    }]
}