<template>
	<card class="filter-grid">
		<template v-slot:header>
			<input-text v-if="!disableSearch" v-model="filters.search" inputmode="search" append-icon="essentials/search/find-circle" @keyup.enter="filters.page = 1; load()" @click:append="filters.page = 1; load()" label="Search..."></input-text>
			<slot name="grid-header" :filters="filters.filters"></slot>
			<div class="actions">
				<slot name="global-actions" :filters="filters"></slot>
				<icon-link :to="createUrl" icon="essentials/basics/add-circle" v-if="createUrl && user.can(createPermission)">Add New</icon-link>
				<icon-button v-if="$scopedSlots.filters" icon="essentials/search/filter-list-circle" @click="filtering = !filtering">Filter</icon-button>
			</div>
		</template>
		<template v-slot:under-header>
			<transition name="slide-down-displace">
				<div class="filters" v-if="filtering" @keyup.enter="load">
					<slot name="filters" :filters="filters.filters" :filter-data="filterData"></slot>
					<div class="actions">
						<icon-button @click.prevent="filters.page = 1; load()" icon="arrows/cycle/reload">Go</icon-button>
					</div>
				</div>
			</transition>
		</template>
		<loader :loading="loading" class="card-loader"></loader>
		<pagination v-model="filters.page" :total="total"></pagination>
		<responsive-table class="filter-table" v-if="items.length > 0" :priority="priority || []">
			<template #header>
				<responsive-th v-for="(column, index) in columns" :class="index" :pos="counts[index]" :key="'header-' + index">
					<a href="#" @click.prevent="setSort(index)" :class="{active: filters.sort === index}">
						{{ column.name }}
						<transition name="fade" mode="out-in">
							<icon v-if="filters.dir === 'asc' && filters.sort === index" icon="essentials/search/sort-ascending" :key="index + '-sort-asc'"></icon>
							<icon v-else-if="filters.sort === index" icon="essentials/search/sort-descending" :key="index + '-sort-desc'"></icon>
						</transition>
					</a>
				</responsive-th>
			</template>
			<responsive-tr v-for="(item, i) in items" :key="'item-' + item.id" @click.prevent="navigate(item)" :total="Object.keys(columns).length">
				<template v-slot="{hidden}">
					<responsive-td 
						v-for="(column, index) in columns" 
						:class="'column-' + ((column.type)?column.type:'text') + ' ' + index" 
						:pos="counts[index]" 
						:hidden="hidden" 
						:key="'col-' + index + '-' + item.id"
						:label="column.name"
					>
						<lazy-background v-if="column.type === 'image'" :src="getValue(index, item)"></lazy-background>
						<span v-if="!column.type || column.type === 'text'">{{ getValue(index, item) }}</span>
					</responsive-td>
				</template>
				<template #actions>
					<slot name="actions" :item="item"></slot>
					<icon-action @click="deleting = item" v-if="user.can(deletePermission)">essentials/basics/close-circle</icon-action>
					<icon-action @click="$navigate(editUrl + '/' + item.id)" v-if="user.can(editPermission) && viewUrl">essentials/settings/edit-circle</icon-action>
				</template>
			</responsive-tr>
		</responsive-table>
		<p v-if="items.length === 0">There are currently no {{ plural }} to view. Why not add one?</p>
		<pagination v-model="filters.page" :total="total" @input="load()"></pagination>
		<modal v-model="deleting" :disabled="deleteLoading">
			<template v-slot:header><h3>Delete {{ (deleting)?deleting[displayKey]:'' }}</h3></template>
			<loader :loading="deleteLoading"></loader>
			<p>Are you sure you really wish to delete {{ (deleting)?deleting[displayKey]:'' }}? Doing so will make this {{ single }} only appear in legacy data.</p>
			<template v-slot:footer>
				<icon-button icon="essentials/alerts/cancel" class="warning" @click="deleting = null" :disabled="deleteLoading">Cancel</icon-button>
				<icon-button icon="essentials/basics/check-circle" class="danger" @click="deleteItem" :disabled="deleteLoading">Delete</icon-button>
			</template>
		</modal>
	</card>
</template>

<script>
	export default {
		props: ['initUrl', 'dataUrl', 'createUrl', 'editUrl', 'deleteUrl', 'createPermission', 'editPermission', 'deletePermission', 'plural', 'single', 'columns', 'displayKey', 'defaultSort', 'defaultSortDir', 'viewUrl', 'viewPermission', 'priority', 'disableSearch', 'viewAction'],
		data() {
			return {
				filters: {
					page: 1,
					dir: (this.defaultSortDir)?this.defaultSortDir:'asc',
					sort: (this.defaultSort)?this.defaultSort:'name',
					search: '',
					filters: {}
				},
				filterData: {},
				total: 1,
				filtering: false,
				loading: false,
				deleting: null,
				deleteLoading: false,
				items: []
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			width() {
				return this.$store.state.screenWidth;
			},
			counts() {
				let count = 0;
				let counts = {};
				Object.keys(this.columns).forEach((column) => {
					counts[column] = count;
					count++;
				});
				return counts;
			}
		},
		watch: {
			'filters.sort': function() {
				this.filters.page = 1;
				this.load();
			},
			'filters.dir': function() {
				this.filters.page = 1;
				this.load();
			}
		},
		methods: {
			async navigate(item) {
				if(this.viewAction && (!this.viewPermission || this.user.can(this.viewPermission))){
					await this.viewAction(item);
					return;
				}
				if(this.viewUrl && (!this.viewPermission || this.user.can(this.viewPermission))){
					await this.$navigate(this.viewUrl + '/' + item.id);
					return;
				}
				if(this.editUrl && (!this.editPermission || this.user.can(this.editPermission))){
					await this.$navigate(this.editUrl + '/' + item.id);
					return;
				}
				
			},
			setSort(index) {
				if(this.filters.sort !== index) {
					this.filters.dir = 'asc';
					this.filters.sort = index;
					return;
				}
				this.filters.dir = (this.filters.dir === 'asc')?'desc':'asc';
			},
			getValue(index, item) {
				let start = item;
				index.split(/\./gi).forEach((key) => {
					if(!start) {
						return;
					}
					start = start[key];
				});
				if(this.columns[index].formatter) {
					return this.columns[index].formatter(start);
				}
				return start;
			},
			async deleteItem() {
				if(this.deleteLoading) {
					return;
				}
				this.deleteLoading = true;
				try {
					const data = await this.$postDelete(this.deleteUrl + '/' + this.deleting.id);
					this.deleting = null;
					this.load();
				} catch(e) {
					if(e.message) {
						this.$setErrors([e.message]);
					}
				}
				this.deleteLoading = false;
			},
			async load(init) {
				if(this.loading) {
					return;
				}
				this.loading = true;
				try {
					if(init === true && this.initUrl) {
						let initData = await this.$request(this.initUrl);
						if(initData.init) {
							this.filters = initData.init;
						}
						delete initData.init;
						this.filterData = initData;
					}
					if(init === true && this.initUrl) {
						this.filters.init = true;
					}
					let data = await this.$request(this.dataUrl, this.filters);
					if(init === true && this.initUrl) {
						delete this.filters.init;
					}
					for(let i = 0; i < data.items.length; i++) {
						data.items[i].open = false;
					}
					this.items = data.items;
					this.total = data.total;
					this.$store.state.mainContent.scrollTop = 0;
				} catch(error) {}
				this.loading = false;
			}
		},
		mounted() {
			this.load(true);
		}
	}
</script>