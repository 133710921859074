<template>
	<main-content class="companies">
		<div class="company-dashboard">Companiesssssss</div>
	</main-content>
</template>

<script>
	export default {
		props: ['value'],
		data() {
			return {
				company: this.value
			};
		},
		watch: {
			company() {
				this.$emit('input', this.company);
			},
			value() {
				this.company = this.value;
			}
		}
	}
</script>