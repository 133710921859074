<template>
	<!-- :style="{width: width + 'px'}"-->
	<div class="top-bar">
		<div class="inner">
			<transition name="fade" mode="out-in">
				<h2 class="page-title" :key="title">{{ title }}</h2>
			</transition>
			<div class="user-menu-toggle" @click.prevent="menuOpen = !menuOpen" v-if="screenWidth >= 980">
				<div class="details" v-outside-click="closeMenu">
					<lazy-background :src="(user.image && user.image.thumb100)?user.image.thumb100:''"></lazy-background>
					<div>
						{{ user.fullname }}<br/><small>{{ user.company.name }}</small>
					</div>
				</div>
				<transition name="fade">
					<div class="dropdown" v-if="menuOpen">
						<user-menu></user-menu>
					</div>
				</transition>
			</div>
			<transition name="fade" mode="out-in">
				<a href="#" @click.prevent="mobileOpen = true" class="menu-toggle" v-if="screenWidth < 980">
					<icon icon="essentials/basics/menu-circle-alt"></icon>
				</a>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menuOpen: false,
				width: 0
			};
		},
		created() {
			window.addEventListener('resize', this.updateWidth);
		},
		destroyed() {
			window.removeEventListener('resize', this.updateWidth);
		},
		computed: {
			title() {
				return this.$store.state.pageTitle;
			},
			screenWidth() {
				return this.$store.state.screenWidth;
			},
			mobileOpen: {
				get() {
					return this.$store.state.sidebarOpen;
				},
				set(value) {
					this.$store.state.sidebarOpen = value;
				}
			},
			user() {
				return this.$store.state.user;
			}
		},
		methods: {
			closeMenu() {
				this.menuOpen = false;
			},
			updateWidth() {
				this.width = window.innerWidth - ((window.innerWidth >= 980)?320:40);
			}
		},
		mounted() {
			this.updateWidth();
			let self = this;
			self.$nextTick(() => {
				self.$store.state.updateBodyHeight();
			});
			
		}
	}
</script>