<template>
	<transition name="fade" mode="out-in">
		<editable-grid-card class="contacts" :permission="(id)?'companies.edit':'companies.create'" :save="save" v-if="id" @cancel="errors = {}" :default-obj="{image_source: {full: ''}}">
			<template #title>
				<icon icon="commerce/wallet/id-card"></icon> 
				Contacts
			</template>
			<template #view="{edit, view}">
				<responsive-table v-if="company.contacts && company.contacts.length > 0" :priority="[2]">
					<responsive-tr v-for="(contact, index) in company.contacts" :key="contact.id" @click.prevent="view(contact)">
						<template v-slot="{hidden}">
							<responsive-td :pos="0" class="image-col" :hidden="hidden"><lazy-background :src="contact.image_source.thumb100"></lazy-background></responsive-td>
							<responsive-td :pos="1" :hidden="hidden">{{ contact.firstname }} {{ contact.lastname }}</responsive-td>
							<responsive-td :pos="2" :hidden="hidden" label="Email">{{ contact.email }}</responsive-td>
						</template>
						<template #actions>
							<icon-action @click="edit(contact)">essentials/settings/edit-circle</icon-action>
							<icon-action @click="deleting = contact">essentials/basics/close-circle</icon-action>
						</template>
					</responsive-tr>
				</responsive-table>
				<p v-else>
					This company has no contacts yet.
				</p>
			</template>
			<template #single="{viewing}">
				<div class="row">
					<div class="col-md-8 col-24">
						<lazy-background class="round" :src="viewing.image_source.full"></lazy-background>
					</div>
					<div class="col-md-16 col-24">
						<div>{{ viewing.fullname }}</div>
						<div>{{ viewing.email }}</div>
						<div>{{ viewing.phone }}</div>
						<div>{{ viewing.address }}</div>
						<div>{{ viewing.city }}, {{ viewing.state }} {{ viewing.zip }}</div>
					</div>
				</div>
			</template>
			<template #edit="{editing}">
				<div class="row">
					<div class="col-md-8 col-24">
						<input-lazy-background v-model="editing.image_source.full" class="round"></input-lazy-background>
					</div>
					<div class="col-md-16 col-24">
						<div class="row">
							<div class="col-md-12 col-24">
								<input-text v-model="editing.firstname" label="Firstname" :errors="errors.firstname"></input-text>
							</div>
							<div class="col-md-12 col-24">
								<input-text v-model="editing.lastname" label="Lastname" :errors="errors.lastname"></input-text>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 col-24">
								<input-text v-model="editing.email" label="Email" :errors="errors.email" inputmode="email"></input-text>
							</div>
							<div class="col-md-12 col-24">
								<input-text v-model="editing.phone" label="Phone" :errors="errors.phone" v-mask="'(###) ###-####'" inputmode="tel"></input-text>
							</div>
						</div>
						<div class="row">
							<div class="col-24">
								<input-address v-model="editing" label="Address" :errors="errors.address" :api-key="form.google_key"></input-address>
							</div>
						</div>
					</div>
				</div>
			</template>
			<delete-modal v-model="deleting" display="fullname" :remove="remove"></delete-modal>
		</editable-grid-card>
	</transition>
</template>

<script>
	export default {
		props: ['value', 'form', 'id'],
		data() {
			return {
				company: this.value,
				deleting: null,
				errors: {}
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			endpoint() {
				return '/json/companies/' + this.id + '/contacts';
			}
		},
		watch: {
			value() {
				this.company = this.value;
			},
			company() {
				this.$emit('input', this.company);
			}
		},
		methods: {
			async remove() {
				const data = await this.$postDelete(this.endpoint + '/' + this.deleting.id);
				this.company.contacts = data.contacts;
			},
			async save(contact) {
				this.errors = {};
				try {
					const data = await this.$upload(this.endpoint, contact, (contact.id)?'patch':'post');
					this.company.contacts = data.contacts;
				} catch(e) {
					this.errors = e.data ?? {};
					throw e;
				}
			}
		}
	}
</script>