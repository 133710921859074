import User from './user';
window.User = User;

import moment from 'moment';
window.moment = moment;

import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';
window.uuidv4 = uuidv4;
window.uuidv1 = uuidv1;

/* Vue setup */
import Vue from 'vue';

import ui from '@pixelcore/ui';
Vue.use(ui);

import PerfectScrollbar from 'vue2-perfect-scrollbar';
Vue.use(PerfectScrollbar);

import vue2Dropzone from 'vue2-dropzone'
Vue.component('vue-dropzone', vue2Dropzone);

/* Require custom components (takes files like MyComponent and creates component aliases of my-component) */
const components = require.context('./components', true, /\.vue$/i);
components.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], components(key).default));

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client';

const sock = SocketIO(process.env.WS_URL, {path: '/'});
Vue.use(new VueSocketIO({
    debug: false,
    connection: sock
}));
sock.on('connect', () => {
    let session = null;
    if(typeof(Storage) !== 'undefined') {
        session = localStorage.getItem('pc_session');
    }
    sock.emit('initialize', {id: session});
});

/* Setup store */
import store from './store';

/* Setup Router */
import router from './router';

/* Require helpers, alias methods onto vue prototype */
const helpers = require.context('./helpers', true, /\.js$/i);
helpers.keys().forEach((key) => {
    const helper = helpers(key).default;
    Object.keys(helper).forEach((method) => {
        if(method[0] === '_') {
            Vue.prototype[method] = helper[method];
        } else {
            Vue.prototype['$' + method] = helper[method];
        }
    }); 
});

import App from './components/App';

/* Vue instance */
const app = new Vue({
    store: store,
    router: router,
    render: h => h(App)
});
window.app = app;
app.sockets.subscribe('initialize', (data) => {
    if(typeof(Storage) !== 'undefined') {
        localStorage.setItem('pc_session', data.token);
    }
    app.$store.state.user = new User(data.user);
    app.$store.state.initialized = true;
});
app.$mount('#app');

app.$store.state.screenWidth = window.innerWidth;
app.$store.state.updateBodyHeight();
window.addEventListener('resize', function (event) {
    app.$store.state.screenWidth = window.innerWidth;
    app.$store.state.updateBodyHeight();
});