<template>
	<main-content>
		<div class="row">
			<div class="col-md-6 col-24">
				<card>
					<template v-slot:header><h4>Weekly Time</h4></template>
					Some time
				</card>
			</div>
			<div class="col-md-6 col-24">
				<card>
					<template v-slot:header><h4>Active Companies</h4></template>
					{{ companies }}
				</card>
			</div>
			<div class="col-md-6 col-24">
				<card>
					<template v-slot:header><h4>Weekly Expenses</h4></template>
					Some time
				</card>
			</div>
			<div class="col-md-6 col-24">
				<card>
					<template v-slot:header><h4>Active Users</h4></template>
					{{ users }}
				</card>
			</div>
		</div>
	</main-content>
</template>

<script>
	export default {
		data() {
			return {
				companies: 0,
				users: 0
			};
		},
		computed: {
			loading: {
				get() {
					return this.$store.state.loading;
				},
				set(value) {
					this.$store.state.loading = value;
				}
			}
		},
		methods: {
			async load() {
				this.loading++;
				try {
					const data = await this.$get('/json/dashboard');
					this.companies = data.companies;
					this.users = data.users;
				} catch(e) {}
				this.loading--;
			}
		},
		async mounted() {
			await this.load();
		}
	}
</script>