<template>
	<div class="login-content" @keyup.enter="login">
		<picture class="logo">
			<source srcset="/images/logo-light.svg" media="(prefers-color-scheme: dark)">
			<img src="/images/logo-dark.svg" />
		</picture>
		<p>Please enter your username and password below to sign in and manage your account.</p>
		<input-text ref="start" v-model="user.username" :errors="errors.username" label="Username/Email" prepend-icon="social/account/avatar-circle" inputmode="email"></input-text>
		<input-password v-model="user.password" :errors="errors.password" label="Password" prepend-icon="essentials/security/key"></input-password>
		<div class="actions">
			<icon-button type="button" icon="arrows/send/in-right" :disabled="loading" @click.prevent="login">Login</icon-button>
			<a href="#" @click.prevent="$emit('forgot')">Forgot Password?</a>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				user: {},
				errors: {},
				loading: false
			};
		},
		watch: {
			loading() {
				this.$emit('loading', this.loading);
			}
		},
		sockets: {
			'login.errors': function(data) {
				this.errors = data;
				this.loading = false;
			},
			login(data) {
				this.loading = false;
				this.$emit('submit', data);
			}
		},
		methods: {
			login() {
				if(this.loading) {
					return;
				}
				this.loading = true;
				this.errors = {};
				this.$socket.emit('login', this.user);
			}
		},
		mounted() {
			this.$refs.start.focus();
		}
	}
</script>