import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading: 0,
        screenWidth: null,
        paddingTop: 0,
        pageTitle: 'Dashboard',
        user: {},
        errors: [],
        messages: [],
        warnings: [],
        webp: '',
        icons: {},
        sidebarOpen: false,
        timer: null,
        settings: window.settings,
        lastUrl: null,
        initialized: false,
        rendered: false,
        updateBodyHeight(){
            const top = document.querySelector('.top-bar');
            let padding = 40;
            if(top) {
                padding += top.getBoundingClientRect().height;
            }
            this.paddingTop = padding;
        }
    }
});