<template>
	<div class="task-item">
		<div class="header">
			<div class="mark">
				<transition name="fade" mode="out-in">
					<icon-action v-if="!item.completed_at" @click="mark(true)" key="mark-complete">essentials/basics/radio-button</icon-action>
					<icon-action v-else @click="mark(false)" key="mark-incomplete">essentials/basics/check-circle</icon-action>
				</transition>
			</div>
			<div class="name">{{ item.name }}</div>
			<div class="task-actions">
				<transition name="fade" mode="out-in">
					<icon-action @click="$socket.emit('start-timer', {task_item_id: item.id})" v-if="!timer || timer.task_item.id !== item.id" key="start-timer">tools/time/time</icon-action>
					<icon-action @click="$socket.emit('stop-timer')" v-if="timer && timer.task_item.id === item.id" key="stop-timer">tools/time/timer-00</icon-action>
				</transition>
				<icon-action @click="$emit('edit')">essentials/settings/edit-circle</icon-action>
				<icon-action @click="$emit('remove')">essentials/basics/close-circle</icon-action>
			</div>
		</div>
		<div class="content" v-if="item.description">{{ item.description }}</div>
	</div>
</template>
<script>
	export default {
		props: ['value', 'id', 'projectId', 'taskId'],
		data() {
			return {
				item: this.value,
				loading: false
			};
		},
		computed: {
			timer() {
				return this.$store.state.timer;
			},
			user() {
				return this.$store.state.user;
			},
			endpoint() {
				return '/json/companies/' + this.id + '/projects/' + this.projectId + '/tasks/' + this.taskId + '/items/' + this.item.id;
			}
		},
		watch: {
			item() {
				this.$emit('input', this.item);
			},
			value() {
				this.item = this.value;
			}
		},
		methods: {
			async mark(completed) {
				if(this.loading) {
					return;
				}
				this.loading = true;
				try {
					this.item = await this.$post(this.endpoint + '/mark', {completed: completed});
				} catch(e) {}
				this.loading = false;
			}
		}
	}
</script>