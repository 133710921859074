<template>
	<div class="side-bar" v-if="user.id" :style="{height: height + 'px', left: (width >= 980 || open && width < 980)?'20px':'-280px'}">
		<perfect-scrollbar :style="{height: (height - 20) + 'px'}">
			<div class="content-holder">
				<router-link to="/">
					<picture class="logo">
						<source srcset="/images/logo-light.svg" media="(prefers-color-scheme: dark)">
						<img src="/images/logo-dark.svg" />
					</picture>
				</router-link>
				<div class="user-info" @click.prevent="openUserMenu">
					<lazy-background :src="(user.image && user.image.thumb100)?user.image.thumb100:''"></lazy-background>
					<div>
						{{ user.fullname }}<br/><small>{{ user.company.name }}</small>
					</div>
				</div>
				<transition name="slide-down-displace">
					<user-menu v-if="width < 980 && userOpen" key="user-sidebar-menu"></user-menu>
				</transition>
			</div>
			<ul>
				<sidebar-link title="Dashboard" route="/" icon="essentials/basics/home-circle-alt" @navigate="open = false" class="dashboard-link"></sidebar-link>
				<sidebar-link title="Companies" route="/companies" permission="companies.view" icon="travel/locations/office-alt-2" @navigate="open = false"></sidebar-link>
				<sidebar-link title="Timesheet" route="/timesheet" icon="tools/time/stopwatch" @navigate="open = false" v-if="user.staff"></sidebar-link>
				<sidebar-link title="Settings" route="/settings" permission="settings.view" icon="essentials/settings/gear" @navigate="open = false"></sidebar-link>
			</ul>
		</perfect-scrollbar>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				height: 0,
				top: 20,
				userOpen: false
			}
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			width() {
				return this.$store.state.screenWidth;
			},
			open: {
				get() {
					return this.$store.state.sidebarOpen;
				},
				set(value) {
					this.$store.state.sidebarOpen = false;
				}
			}
		},
		created() {
			window.addEventListener('resize', this.updateHeight);
		},
		destroyed() {
			window.removeEventListener('resize', this.updateHeight);
		},
		methods: {
			openUserMenu() {
				if(this.width >= 980) {
					return;
				}
				this.userOpen = !this.userOpen;
			},
			updateHeight() {
				this.height = window.innerHeight - 40;
			}
		},
		mounted() {
			this.updateHeight();
		}
	}
</script>