<template>
	<transition name="fade" mode="out-in">
		<div class="global-timer" v-if="timer" v-outside-click="close">
			<div class="info" v-if="open">
				<lazy-background :src="'/uploads/companies/' + timer.task_item.task.project.company.id + '/100.jpeg'"></lazy-background>
				<div class="content">
					<div class="heading">{{ timer.task_item.task.project.company.name }} - {{ timer.task_item.task.project.name }}</div>
					<div class="subheading">{{ timer.task_item.task.name }} - {{ timer.task_item.name }}</div>
					
					<div class="duration">
						{{ timer.duration }}hrs
						
					</div>
				</div>
            </div>
            <transition name="fade" mode="out-in">
	            <icon-action @click="open = true" key="open-timer" v-if="!open">tools/time/alarm</icon-action>
	            <icon-action @click="open = false" key="close-timer" v-if="open">essentials/basics/close-circle</icon-action>
	        </transition>
        </div>
    </transition>
</template>

<script>
	export default {
		data() {
			return {
				open: false
			};
		},
		computed: {
			timer() {
				return this.$store.state.timer;
			}
		},
		methods: {
			close() {
				this.open = false;
			}
		}
	}
</script>