<template>
	<transition name="fade" mode="out-in">
		<editable-grid-card class="tasks" :permission="(id)?'companies.edit':'companies.create'" :save="save" v-if="projectId" @cancel="errors = {}">
			<template #title>
				<icon icon="office/tasks/clipboard-tasks"></icon> 
				Tasks
			</template>
			<template #view="{edit}">
				<responsive-table v-if="project.tasks && project.tasks.length > 0" :priority="[]">
					<responsive-tr v-for="(task, index) in project.tasks" :key="task.id" @click.prevent="selected = task">
						<template v-slot="{hidden}">
							<responsive-td :pos="0" :hidden="hidden">{{ task.name }}</responsive-td>
						</template>
						<template #actions>
							<icon-action @click="edit(task)">essentials/settings/edit-circle</icon-action>
							<icon-action @click="deleting = task">essentials/basics/close-circle</icon-action>
						</template>
					</responsive-tr>
				</responsive-table>
				<p v-else>
					This project has no tasks yet.
				</p>
			</template>
			<template #edit="{editing}">
				<input-text label="Task Name" v-model="editing.name" type="text" :errors="errors.name"></input-text>
			</template>
			<delete-modal v-model="deleting" display="name" :remove="remove"></delete-modal>
		</editable-grid-card>
	</transition>
</template>

<script>
	export default {
		props: ['value', 'active', 'form', 'id', 'projectId'],
		data() {
			return {
				project: this.value,
				deleting: null,
				selected: this.active,
				errors: {}
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			endpoint() {
				return '/json/companies/' + this.id + '/projects/' + this.projectId + '/tasks';
			}
		},
		watch: {
			value() {
				this.project = this.value;
			},
			company() {
				this.$emit('input', this.project);
			},
			active() {
				this.selected = this.active;
			},
			selected() {
				this.$emit('input:active', this.selected);
			}
		},
		methods: {
			async remove() {
				const data = await this.$postDelete(this.endpoint + '/' + this.deleting.id);
				this.project.tasks = data.tasks;
			},
			async save(task) {
				this.errors = {};
				try {
					const data = await this[((task.id)?'$patch':'$post')](this.endpoint, task);
					this.project.tasks = data.tasks;
				} catch(e) {
					this.errors = e.data ?? {};
					throw e;
				}
			}
		}
	}
</script>