<template>
	<editable-card class="details" :permission="(projectId)?'companies.edit':'companies.create'" :save="save" :edit-only="!projectId" @cancel="errors = {}">
		<template #title>
			<icon icon="office/documents/drawer"></icon>
			Details
		</template>
		<template #view>
			<lazy-background :src="project.image_source.full" class="card-image project-image"></lazy-background>
			<div class="info">
				<h4>{{ project.name }}</h4>
				<company-link :company="project.company"></company-link>
			</div>
		</template>
		<template #edit>
			<input-lazy-background v-model="eproject.image_source.full" class="project-image"></input-lazy-background>
			<div class="info">
				<input-text type="text" v-model="eproject.name" label="Project Name" :errors="errors.name"></input-text>
			</div>
		</template>
	</editable-card>
</template>

<script>
	export default {
		props: ['value', 'id', 'projectId'],
		data() {
			return {
				project: this.value,
				eproject: this.$clone(this.value),
				errors: {}
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			endpoint() {
				return '/json/companies/' + this.id + '/projects/' + ((this.projectId)?this.projectId:'create');
			}
		},
		watch: {
			value() {
				this.project = this.value;
				this.eproject = this.$clone(this.value);
			},
			project() {
				this.$emit('input', this.project);
			}
		},
		methods: {
			async save() {
				this.errors = {};
				try {
					const data = await this.$upload(this.endpoint + '/details', this.eproject, (this.projectId)?'patch':'post');
					this.project = data.project;
					this.eproject = data.project;
					if(!this.projectId) {
						await this.$navigate('/companies/' + this.id + '/projects/' + this.project.id);
					}
				} catch(e) {
					this.errors = e.data ?? {};
					throw e;
				}
			}
		}
	}
</script>