<template>
	<transition name="fade" mode="out-in">
		<editable-grid-card class="secrets" :permission="(id)?'companies.edit':'companies.create'" :save="save" v-if="id" @cancel="errors = {}" :default-obj="{image_source: {full: ''}, fields: []}">
			<template #title>
				<icon icon="essentials/security/fingerprint"></icon> 
				Secrets
			</template>
			<template #view="{edit, view}">

				<responsive-table v-if="company.secrets && company.secrets.length > 0" :priority="[]">
					<responsive-tr v-for="(secret, index) in company.secrets" :key="secret.id" @click.prevent="view(secret)">
						<template v-slot="{hidden}">
							<responsive-td :pos="0" class="image-col" :hidden="hidden"><lazy-background :src="secret.image_source.thumb100"></lazy-background></responsive-td>
							<responsive-td :pos="1" :hidden="hidden">{{ secret.name }}</responsive-td>
						</template>
						<template #actions>
							<icon-action @click="edit(secret)">essentials/settings/edit-circle</icon-action>
							<icon-action @click="deleting = secret">essentials/basics/close-circle</icon-action>
						</template>
					</responsive-tr>
				</responsive-table>
				<p v-else>
					This company has no secrets yet.
				</p>
			</template>
			<template #single="{viewing}">
				<h4>{{ viewing.name }}</h4>
				<responsive-table v-if="viewing.fields && viewing.fields.length > 0" :priority="[]">
					<responsive-tr v-for="(field, index) in viewing.fields" :key="field.id" :no-hover="true">
						<template v-slot="{hidden}">
							<responsive-td :pos="0" :hidden="hidden">{{ field.name }}</responsive-td>
							<responsive-td :pos="1" :hidden="hidden">{{ field.secret_value }}</responsive-td>
						</template>
						<template #actions>
							<transition name="fade" mode="out-in">
								<icon-action @click="view(viewing.fields[index])" v-if="field.type === 'password' && !field.open" key="view-field">tools/design/view</icon-action>
								<icon-action @click="hide(viewing.fields[index])" v-if="field.type === 'password' && field.open" key="hide-field">tools/design/hide</icon-action>
							</transition>
							<icon-action @click="copy(field)">office/documents/paper-stack</icon-action>
						</template>
					</responsive-tr>
				</responsive-table>
				<p v-else>
					This secret has no fields yet.
				</p>
			</template>
			<template #edit="{editing}">
				<input-text v-model="editing.name" label="Secret Name" :errors="errors.name"></input-text>
				<h4 class="field-header">
					Fields
					<a href="#" @click.prevent="addField(editing)"><icon icon="essentials/basics/add-circle"></icon></a>
				</h4>
				<secret-field 
					v-for="(field, index) in editing.fields" 
					:key="'field-' + index" 
					v-model="editing.fields[index]" 
					:decrypt="endpoint + '/decrypt/' + editing.id"
					:errors="$errors(errors, 'fields.' + index)"
					@remove="editing.fields.splice(index, 1)"
				></secret-field>
			</template>
			<delete-modal v-model="deleting" display="name" :remove="remove"></delete-modal>
		</editable-grid-card>
	</transition>
</template>

<script>
	export default {
		props: ['value', 'form', 'id'],
		data() {
			return {
				company: this.value,
				deleting: null,
				errors: {}
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			endpoint() {
				return '/json/companies/' + this.id + '/secrets';
			}
		},
		watch: {
			value() {
				this.company = this.value;
			},
			company() {
				this.$emit('input', this.company);
			}
		},
		methods: {
			addField(secret) {
				if(!secret.fields) {
					this.$set(secret, 'fields', []);
				}
				secret.fields.push({type: 'text'});
			},
			async remove() {
				const data = await this.$postDelete(this.endpoint + '/' + this.deleting.id);
				this.company.secrets = data.secrets;
			},
			async save(secret) {
				this.errors = {};
				try {
					const data = await this[((secret.id)?'$patch':'$post')](this.endpoint, secret);
					this.company.secrets = data.secrets;
				} catch(e) {
					this.errors = e.data ?? {};
					throw e;
				}
			},
			async view(field) {
				field.secret_value = await this.$decrypt(this.endpoint + '/decrypt/' + field.secret_id + '/' + field.id);
				this.$set(field, 'open', true);
			},
			async hide(field) {
				field.secret_value = '••••••••';
				this.$set(field, 'open', false);
			},
			async copy(field) {
				if(field.type === 'password') {
					const value = await this.$decrypt(this.endpoint + '/decrypt/' + field.secret_id + '/' + field.id);
					this.$copy(value);
					return;
				}
				this.$copy(field.secret_value);
			}
		}
	}
</script>