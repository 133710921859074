<template>
	<div class="secret">
		<input-text v-model="secret.name" label="Name" :errors="errors.name" :prepend-icon="(secret.type === 'text')?'essentials/security/padlock-open':'essentials/security/padlock'" @click:prepend="secret.type = (secret.type === 'text')?'password':'text'"></input-text>
		<transition name="fade" mode="out-in">
			<input-encrypted v-if="secret.type === 'password'" v-model="secret.secret_value" label="Password" :errors="errors.secret_value" :endpoint="decrypt + '/' + secret.id"></input-encrypted>
			<input-text v-else v-model="secret.secret_value" label="Value" :errors="errors.secret_value"></input-text>
		</transition>
		<a href="#" @click.prevent="$emit('remove')"><icon icon="essentials/basics/close-circle"></icon></a>
		<a href="#" @click.prevent="copy"><icon icon="office/documents/paper-stack"></icon></a>
	</div>
</template>

<script>
	export default {
		props: ['value', 'errors', 'decrypt'],
		data() {
			return {
				secret: this.value
			};
		},
		watch: {
			value() {
				this.secret = this.value;
			},
			secret() {
				this.$emit('input', this.secret);
			},
			'secret.type': async function(n, o) {
				if(n === 'text' && o === 'password' && this.secret.secret_value === '••••••••') {
					this.secret.secret_value = await this.$decrypt(this.decrypt + '/' + this.secret.id);
				}
			}
		},
		methods: {
			async copy() {
				if(this.secret.type === 'password') {
					const value = await this.$decrypt(this.decrypt + '/' + this.secret.id);
					this.$copy(value);
					return;
				}
				this.$copy(this.secret.secret_value);
			}
		}
	}
</script>