<template>
	<div id="app" :class="{'logged-in': user.id}">
        <transition name="fade">
            <div class="mobile-back" v-if="$store.state.screenWidth < 980 && $store.state.sidebarOpen">
                <a href="#" @click.prevent="$store.state.sidebarOpen = false" class="menu-toggle">
                    <icon icon="essentials/basics/close-circle"></icon>
                </a>
            </div>
        </transition>
        <loader class="global-loader" :loading="$store.state.loading > 0"></loader>
        <transition name="fade">
            <sidebar></sidebar>
        </transition>
        <perfect-scrollbar :style="{height: height + 'px'}" class="main-scroll">
            <transition name="fade">
                <topbar v-if="user.id"></topbar>
            </transition>
            <transition name="fade" mode="out-in">
                <router-view :key="$route.fullPath"></router-view>
            </transition>
        </perfect-scrollbar>
        <div class="alert-stack">
            <alert v-model="$store.state.errors" type="danger" icon="essentials/alerts/cancel"></alert>
            <alert v-model="$store.state.messages" type="info" icon="essentials/alerts/info-circle"></alert>
            <alert v-model="$store.state.warnings" type="warning" icon="essentials/alerts/exclamation-circle"></alert>
        </div>
        <global-timer></global-timer>
	</div>
</template>

<script>
    export default {
        data() {
            return {
                height: 0
            };
        },
        computed: {
            ready() {
                return this.$store.state.initialized && this.$store.state.rendered;
            },
            user() {
                return this.$store.state.user;
            }
        },
        watch: {
            async ready() {
                if(!this.ready) {
                    return;
                }
                await this.$nextTick();
                document.getElementById('splash').style.opacity = 1;
                window.requestAnimationFrame(this.fadeSplash);
            }
        },
        sockets: {
           /*,
            'set-active-timer': function(data) {
                this.$store.state.timer = data;
            },
            'active-timer': function(data) {
                this.$store.state.timer.duration = data.duration;
                this.$store.state.timer.day = data.day;
            }*/
        },
        created() {
            window.addEventListener('resize', this.updateHeight);
        },
        destroyed() {
            window.removeEventListener('resize', this.updateHeight);
        },
        methods: {
            fadeSplash() {
                const splash = document.getElementById('splash');
                splash.style.opacity = splash.style.opacity - 0.05;
                if(splash.style.opacity <= 0){
                    splash.parentNode.removeChild(splash);
                    return;
                }
                window.requestAnimationFrame(this.fadeSplash);
            },
            updateHeight() {
                this.height = window.innerHeight;
            }
        },
        mounted() {
            this.updateHeight();
            const webp = document.createElement('canvas');
            if (!!(webp.getContext && webp.getContext('2d'))) {
                if(webp.toDataURL('image/webp').indexOf('data:image/webp') == 0) {
                    this.$store.state.webp = '.webp';
                }
            }
            this.$store.state.rendered = true;
        }
    }
</script>