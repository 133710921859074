export default {
    routes: [{
        path: '/timesheet',
        component: () => import('../pages/timesheet/Index.vue'),
        name: 'timesheet.index',
        meta: {
            name: 'Timesheet',
            title: window.titlePrefix,
            permissions: ['companies.view']
        }
    }]
}