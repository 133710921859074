<template>
	<div class="media-library-uploader">
		<transition name="slide-down-displace">
			<div v-if="open">
				<vue-dropzone ref="uploader" :id="_uid.toString()" :options="dropzone" @vdropzone-file-added="upload"></vue-dropzone>
			</div>
		</transition>
		<div class="uploading" v-if="Object.keys(uploads).length > 0">
			<div class="upload" v-for="(upload, index) in uploads">
				<span class="name">{{ upload.name }}</span>
				<span class="right">
					{{ humanSize(upload.bytes) }}/{{ humanSize(upload.total) }}
					<span class="progress">
						<span class="filler" :style="{width: upload.progress + '%'}"></span>
						<span class="percent">{{ upload.progress.toFixed(1) }}%</span>
					</span>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['open', 'value'],
		data() {
			return {
				uploads: {},
				folder: this.value
			};
		},
		watch: {
			value() {
				this.folder = this.value;
			},
			folder() {
				this.$emit('input', this.folder);
			}
		},
		computed: {
			dropzone() {
				return {
					url: '',
					autoProcessQueue: false
				};
			}
		},
		methods: {
			humanSize(size) {
				let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    			return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
			},
			async upload(file) {
				this.$refs.uploader.removeFile(file);
				this.$set(this.uploads, file.upload.uuid, {
					name: file.name, 
					progress: 0,
					bytes: 0,
					total: file.size
				});
				this.sendChunk(file, 0);
			},
			sendChunk(file, index) {
				const chunkSize = 1024 * 512;
				const blob = file.slice(index * chunkSize, (index * chunkSize) + chunkSize);
				if(blob.size > 0) {
					this.sockets.subscribe('media-upload:' + file.upload.uuid + ':chunk' + index, (data) => {
						this.sockets.unsubscribe('media-upload:' + file.upload.uuid + ':chunk' + index);
						this.uploads[file.upload.uuid].bytes = (chunkSize * index);
						this.uploads[file.upload.uuid].progress = Math.round(((chunkSize * index) / file.size) * 1000) / 10;
						this.sendChunk(file, index + 1);
					});
					this.$socket.emit('media-upload', {
						file: {
							type: file.type,
							name: file.name,
							size: file.size,
							uuid: file.upload.uuid,
							folder: (this.folder)?this.folder.id:null
						}, 
						slice: blob,
						chunk: index
					});
				} else {
					this.sockets.subscribe('media-upload:' + file.upload.uuid + ':finish', (data) => {
						this.sockets.unsubscribe('media-upload:' + file.upload.uuid + ':finish');
						this.$delete(this.uploads, file.upload.uuid);
						this.folder.media.push(data.media);
					});
					this.$socket.emit('media-upload', {
						file: {
							type: file.type,
							name: file.name,
							size: file.size,
							uuid: file.upload.uuid,
							folder: (this.folder)?this.folder.id:null
						}, 
						chunk: null
					});
				}
			}
		}
	}
</script>