<template>
	<transition name="fade" mode="out-in">
		<editable-grid-card v-if="task" class="task-view" :save="save" @cancel="errors = {}" :permission="(id)?'companies.edit':'companies.create'" :default-obj="{image_source: {full: ''}}">
			<template #title>
				<icon icon="tools/text/bullet-list"></icon>
				{{ task.name }}
			</template>
			<template #view="{edit}">
				<div class="task-items" v-if="task.items.length > 0">
					<task-item v-for="(item, index) in task.items" v-model="task.items[index]" :key="item.id" @edit="edit(item)" @remove="deleting = item" :id="id" :project-id="projectId" :taskId="task.id"></task-item>
				</div>
				<p v-else>This task has no items assigned to it yet.</p>
			</template>
			<template #edit="{editing}">
				<input-text v-model="editing.name" label="To Do" :errors="errors.name"></input-text>
				<input-textarea v-model="editing.description" label="Additional Details" :errors="errors.description"></input-textarea>	
			</template>
			<delete-modal v-model="deleting" display="name" :remove="remove"></delete-modal>
		</editable-grid-card>
	</transition>
</template>

<script>
	export default {
		props: ['value', 'form', 'id', 'projectId'],
		data() {
			return {
				task: this.value,
				deleting: null,
				errors: {}
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			endpoint() {
				return '/json/companies/' + this.id + '/projects/' + this.projectId + '/tasks/' + this.task.id + '/items';
			}
		},
		watch: {
			task() {
				this.$emit('input', this.task);
			},
			value() {
				this.task = this.value;
			}
		},
		methods: {
			async remove() {
				const data = await this.$postDelete(this.endpoint + '/' + this.deleting.id);
				this.task.items = data.items;
			},
			async save(item) {
				this.errors = {};
				try {
					const data = await this[(item.id)?'$patch':'$post'](this.endpoint, item);
					this.task.items = data.items;
				} catch(e) {
					this.errors = e.data ?? {};
					throw e;
				}
			}
		}
	}
</script>