<template>
	<div class="login-content" @keyup.enter="login">
		<div class="profile">
			<lazy-background :src="(user.image && user.image.thumb100)?user.image.thumb100:''"></lazy-background>
			<h3>{{ user.fullname }}<br/><small>@{{ user.username }}</small></h3>
		</div>
		<p>We sent a code to your phone number at {{ user.phone_masked }}, please enter it to finish logging in.</p>
		<input-text ref="code-input" prepend-icon="essentials/security/keychain" v-model="code.code" label="6 Digit Code" :errors="errors.code" autocomplete="off" v-mask="'######'" inputmode="decimal"></input-text>
		<div class="actions">
			<icon-button type="button" :disabled="loading" @click.prevent="login" icon="essentials/security/padlock-open">Verify</icon-button>
			<a href="#" @click.prevent="resend">Resend Code</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['user'],
		data() {
			return {
				code: {},
				errors: {},
				loading: false
			};
		},
		watch: {
			loading() {
				this.$emit('loading', this.loading);
			}
		},
		sockets: {
			'2fa.errors': function(data) {
				this.errors = data;
				this.loading = false;
			},
			'2fa': async function(data) {
				this.loading = false;
				await this.setStore(data);
			}
		},
		methods: {
			login() {
				if(this.loading) {
					return;
				}
				this.code.id = this.user.id;
				this.loading = true;
				this.errors = {};
				this.$socket.emit('2fa', this.code);
			},
			async setStore(user) {
				this.$store.state.user = new User(user);
				await this.$nextTick();
				this.$store.state.updateBodyHeight();
				if(this.$store.state.lastUrl) {
					await this.$navigate(this.$store.state.lastUrl);
				}
			},
			async resendCode() {
				this.loading = true;
				try {
					await this.$post('/json/resend-code', this.user);
				} catch(e) {}
				this.loading = false;
			}
		},
		mounted() {
			this.$refs['code-input'].focus();
		}
	}
</script>