import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

/* Auto load all route files */
let routes = [];
const rfiles = require.context('./routes', true, /\.js$/i);
rfiles.keys().forEach((key) => {
    const obj = rfiles(key).default;
    if(obj && obj.routes) {
        routes = routes.concat(obj.routes);
    }
});

const router = new VueRouter({
    mode: 'history',
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    if(to.name === 'index') {
        store.state.pageTitle = to.meta.name;
        await next();
        return;
    }
    if(to.meta.permissions) {
        const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        while(!store.state.initialized) {
            await sleep(100);
        }
        await store.state.user.refresh();
        if(!store.state.user.permissions) {
            store.state.user = new User(null);
            await next('/');
            return;
        }
        if(!store.state.user.canAny(to.meta.permissions)) {
            store.state.lastUrl = to.path;
            await next(to.meta.redirect || '/');
            return;
        }
    }
    store.state.pageTitle = to.meta.name;
    await next();
});

export default router; 