<template>
	<div class="logged-out">
		<div class="left-container"></div>
		<div class="login">
			<loader :loading="loading"></loader>
			<perfect-scrollbar :style="{height: height + 'px'}">
				<transition name="fade" mode="out-in">
					<login-form key="login-form" v-if="!forgot && !user && !resetting" @submit="loading = false; user = $event" @forgot="forgot = true" @loading="loading = $event"></login-form>
					<two-factor-form key="code-form" v-else-if="user" :user="user" @loading="loading = $event"></two-factor-form>
					<send-reset-form key="reset-form" v-else-if="forgot && !user" @back="forgot = false" @loading="loading = $event"></send-reset-form>
					<reset-password-form key="reset-password-form" v-else-if="resetting" @loading="loading = $event"></reset-password-form>
				</transition>
			</perfect-scrollbar>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				forgot: false,
				resetting: false,
				user: null,
				height: 0
			};
		},
		watch: {
			'$route.path': function() {
				if(this.$route.path === '/reset-password') {
					this.resetting = true;
				} else {
					this.resetting = false;
				}
			},
			async resetting() {
				if(this.resetting && !this.$route.query.key) {
					await this.$navigate('/');
					return;
				}
				try {
					const reset = await this.$post('/json/reset-password', {key: this.$route.query.key});
				} catch(e) {
					await this.$navigate('/');
				}
			}
		},
		methods: {
			calcHeight() {
				this.height = window.innerHeight - 80;
			}
		},
		created() {
			window.addEventListener('resize', this.calcHeight);
		},
		destroyed() {
			window.removeEventListener('resize', this.calcHeight);
		},
		mounted() {
			this.calcHeight();
			if(this.$route.path === '/reset-password') {
				this.resetting = true;
			}
		}
	}
</script>