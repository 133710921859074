<template>
	<li v-if="!permission || user.can(permission)">
		<router-link :to="route" @click.native="$emit('navigate')">
			{{ title }}
			<icon :icon="icon"></icon>
		</router-link>
		<transition name="slide-down-displace">
			<ul class="submenu" v-if="$slots.default && open">
				<slot></slot>
			</ul>
		</transition>
	</li>
</template>

<script>
	export default {
		props: ['route', 'icon', 'permission', 'title'],
		computed: {
			user() {
				return this.$store.state.user;
			},
			open() {
				const regex = new RegExp(this.route + '.*', 'gmi');
				return regex.test(this.$route.fullPath);
			}
		}
	}
</script>