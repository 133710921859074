export default {
    routes: [{
        path: '/',
        component: () => import('../pages/Index.vue'),
        name: 'index',
        meta: {
            name: 'Dashboard',
            title: window.titlePrefix
        }
    }, {
        path: '/reset-password',
        component: () => import('../pages/Index.vue'),
        name: 'reset-password',
        meta: {
            name: 'Forgot Password',
            title: window.titlePrefix
        }
    }, {
    	path: '*',
    	redirect: '/'
    }]
}