export default class User {

    permissions = [];

    constructor(data) {
        if(data) {
            Object.keys(data).forEach((index) => {
                this[index] = data[index];
            });
        }
    }

    can(perm) {
        if(!this.permissions || this.permissions.length === 0) {
            return false;
        }
        return this.permissions.indexOf(perm) !== -1;
    }

    canAny(perms) {
        let can = false;
        perms.forEach((perm) => {
            if(this.can(perm)) {
                can = true;
            }
        });
        return can;
    }

    canAll(perms) {
        let can = true;
        perms.forEach((perm) => {
            if(!this.can(perm)) {
                can = false;
            }
        });
        return can;
    }

    refresh() {
        return new Promise((resolve, reject) => {
            window.app.$store.state.loading++;
            window.app.sockets.subscribe('permissions', (data) => {
                window.app.sockets.unsubscribe('permissions');
                window.app.$store.state.loading--;
                this.permissions = data.permissions;
                resolve();
            });
            window.app.$socket.emit('permissions');
        });
    }

}