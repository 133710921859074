<template>
	<input-form action="/json/reset" @submit="$navigate('/')" @loading="$emit('loading', $event)" :unsaveable="true" v-slot="props" :object-data="reset">
		<div class="login-content">
			<picture class="logo">
				<source srcset="/images/logo-light.svg" media="(prefers-color-scheme: dark)">
				<img src="/images/logo-dark.svg" />
			</picture>
			<p>Enter the code you received in the email along with a new password to reset your account.</p>
			<input-text ref="start" v-model="props.object.code" :errors="props.errors.code" label="Reset Code" prepend-icon="essentials/security/safe"></input-text>
			<input-password v-model="props.object.password" :errors="props.errors.password" label="New Password" prepend-icon="essentials/security/key"></input-password>
			<input-password v-model="props.object.password_v" :errors="props.errors.password_v" label="Reenter New Password" prepend-icon="essentials/security/keyhole-circle"></input-password>
			<button type="submit" :disabled="props.loading > 0" class="secondary block">Reset Password</button>
		</div>
	</input-form>
</template>

<script>
	export default {
		data() {
			return {
				reset: {}
			};
		},
		watch: {
			'$route.query': {
				handler() {
					this.reset.key = this.$route.query.key;
				},
				immediate: true
			}
		}
	}
</script>